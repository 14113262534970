import { Fragment } from "react";
import { bool, object, func } from "prop-types";
import { usePresetFieldNames } from "@hooks";
import { isEmptyHtml } from "project-structure";
import { HtmlParser } from "@utils";
import { ExportTask } from "./ExportTask";

export const ExportTableBreakdown = ({
  editorStore,
  usePreset,
  translate,
  allowShowingPrices,
}) => {
  const { currentProjectStructure } = editorStore;
  const { workTypes, settings, visibility, sections, estimateViewLevel, estimateValueLevel, usesTwoValues, usesAnyValue } = currentProjectStructure;
  const { currencyObj, showPrices, sectionFormat, showBreakdownColumns } = settings;
  const { apply } = visibility;
  const { getFieldName } = usePresetFieldNames(usePreset, editorStore);

  return (
    <div className="breakdown">
      <h3 className="breakdown">{getFieldName("pt-breakdown")}</h3>
      {sections.map((section) => {
        const {
          id: sectionId,
          children,
          taskWorkTypes,
          name,
          content,
        } = section;

        return (
          <Fragment key={sectionId}>
            {sectionFormat > 1 && (
              <>
                <h3 className="section">
                  {name || translate("common.unnamed")}
                </h3>
                {!isEmptyHtml(content) && (
                  <div className="sectionDesc">{HtmlParser(content)}</div>
                )}
              </>
            )}
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: "left" }}>
                    {getFieldName("pt-module", sectionFormat === 1 ? 0 : 1)}
                  </th>
                  {showBreakdownColumns &&
                    taskWorkTypes
                      .filter(workType => !apply || !workType.isHidden)
                      ?.map(({ id }) => (
                      <th key={id}>
                        {workTypes.find((wT) => wT.id === id)?.name ||
                          translate("common.unnamed")}
                      </th>
                    ))}
                  {showBreakdownColumns && allowShowingPrices && showPrices && (
                    <th>
                      {`${getFieldName("pt-price")} (${
                        currencyObj.symbolStart || currencyObj.symbolEnd
                      })`}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {sectionFormat === 1 && (
                  <>
                    <ExportTask
                      translate={translate}
                      key={sectionId}
                      tableDepth={0}
                      task={section}
                      editorStore={editorStore}
                      settings={settings}
                      visibility={visibility}
                      allowShowingPrices={allowShowingPrices}
                      estimateViewLevel={estimateViewLevel}
                      estimateValueLevel={estimateValueLevel}
                      usesTwoValues={usesTwoValues}
                      usesAnyValue={usesAnyValue}
                      noContent
                    />
                    {!isEmptyHtml(content) && (
                      <tr>
                        <td
                          className="sectionDesc"
                          colSpan={
                            1 +
                            (showBreakdownColumns
                              ? taskWorkTypes.length +
                                (allowShowingPrices && showPrices ? 1 : 0)
                              : 0)
                          }
                        >
                          {HtmlParser(content)}
                        </td>
                      </tr>
                    )}
                  </>
                )}
                {children?.map((s, i) => (
                  <ExportTask
                    translate={translate}
                    key={s.id || s.newId}
                    index={`${i + 1}`}
                    tableDepth={1}
                    editorStore={editorStore}
                    task={s}
                    settings={settings}
                    visibility={visibility}
                    allowShowingPrices={allowShowingPrices}
                    estimateViewLevel={estimateViewLevel}
                    estimateValueLevel={estimateValueLevel}
                    usesTwoValues={usesTwoValues}
                    usesAnyValue={usesAnyValue}
                  />
                ))}
                {sectionFormat === 2 && section.children?.length > 0 && (
                  <>
                    <ExportTask
                      translate={translate}
                      key={sectionId}
                      tableDepth={-1}
                      task={section}
                      editorStore={editorStore}
                      settings={settings}
                      visibility={visibility}
                      allowShowingPrices={allowShowingPrices}
                      estimateViewLevel={estimateViewLevel}
                      estimateValueLevel={estimateValueLevel}
                      usesTwoValues={usesTwoValues}
                      usesAnyValue={usesAnyValue}
                      isSubtotal
                      noContent
                    />
                  </>
                )}
              </tbody>
            </table>
          </Fragment>
        );
      })}
    </div>
  );
};

ExportTableBreakdown.propTypes = {
  editorStore: object,
  usePreset: bool,
  allowShowingPrices: bool,
  translate: func,
};
