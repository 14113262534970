import { fetchData, APIMethod, ACCESS_DOMAINS } from "@client";

export async function getIssuesWorkType(projectId) {
  return await fetchData(`api/get-issue-work-type`, APIMethod.POST, {
    baseURL: ACCESS_DOMAINS.btApi,
    data: {
      projectId: projectId,
    },
  });
}
