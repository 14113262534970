import { useEffect, useState } from "react";
import {
  EditorCommentsStoreProvider,
  EditorSocketProvider,
  PresetStoreProvider,
  ProjectEditorStoreProvider,
} from "@stores";
import { fetchProjectProposalQuery, getProjectPresetQuery } from "@query";
import { useSharedProjectKey } from "@hooks";
import { Dialog, PageCircularProgress } from "@components";
import { ProposalView } from "./ProposalView";
import { useTranslation } from "react-i18next";
import { HtmlParser, PROJECT_TYPE } from "@utils";
import { useParams } from "react-router-dom";
import { db } from "@client";
import { structureParser, unzipStructure } from "project-structure";

export const Proposal = () => {
  const key = useSharedProjectKey();
  const { projectHash } = useParams();
  const { t } = useTranslation();

  const [data, setData] = useState(null);
  const [commenterData, setCommenter] = useState(null);
  const [isRemoved, setRemoved] = useState(false);
  const [isArchived, setArchived] = useState(false);
  
  useEffect(() => {
    document.title = "Estimate";
    if(!self.location.href.includes("app.apropo")) {
      const favicon = document.getElementById("favicon");
      favicon.setAttribute(
        "href",
        self.location.href.includes("estimates.whitelabelcoders.com")
          ? "https://whitelabelcoders.com/app/themes/wlc-new-site/dist/images/favicon/favicon-32x32_f7102d9c.png"
          : "data:;base64,iVBORw0KGgo="
      );
    }
    const abortController = new AbortController();
    onLoad();
    return () => abortController.abort();
  }, []);

  const onLoad = async () => {
    try {
      const commenter = await db.comment.where({ project: projectHash })?.toArray();
      setCommenter(commenter?.[0]);
      const projectPreset = await getProjectPresetQuery(key);
      const projectData = await fetchProjectProposalQuery(key, true);

      if (projectData.status === "archive") {
        setArchived(true);
        return;
      }
      
      for(const ver of projectData.versions) {
        ver.structure = await structureParser({
          structureData: unzipStructure(ver.structure),
          workTypes: projectData.tags
        });
      }
      
      setData({ projectData, projectPreset });
    } catch (e) {
      if (e?.response?.data?.status === 400) setArchived(true);
      else if (
        e?.response?.data?.status === 403 ||
        e?.response?.data?.status === 404
      )
        setRemoved(true);
    }
  };

  if (isRemoved)
    return (
      <Dialog open>
        <h3>{HtmlParser(t("alerts.editor.removed_share"))}</h3>
      </Dialog>
    );

  if (isArchived)
    return (
      <Dialog open>
        <h3>{HtmlParser(t("alerts.editor.archive_share"))}</h3>
      </Dialog>
    );

  return !data ? (
    <PageCircularProgress />
  ) : (
    <ProjectEditorStoreProvider
      projectType={PROJECT_TYPE.STANDARD}
      projectData={data.projectData}
      logo={data.logo}
      unzipped
      isProposal
    >
      <PresetStoreProvider projectPreset={data.projectPreset} useFontManager>
        <EditorCommentsStoreProvider
          isProposal
          commenterData={commenterData}
          useComments={data.projectData.comment}
        >
          <EditorSocketProvider
            projectUuid={projectHash}
            commentHash={data.projectData.commentUuid}
          >
            <ProposalView />
          </EditorSocketProvider>
        </EditorCommentsStoreProvider>
      </PresetStoreProvider>
    </ProjectEditorStoreProvider>
  );
};
