import { useMemo } from "react";
import { bool } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { usePresetFieldNames, useStructureStore, useVisibilityModeCellClassName } from "@hooks";
import { ResetValueButton } from "@components";
import { TotalContainer } from "../components";

export const TotalTime = observer(({
  allowEdition,
  isEditorOrClient,
  useClientActions,
  ...containerProps
}) => {
  const {t} = useTranslation();
  const structure = useStructureStore();
  const { getFieldName } = usePresetFieldNames(containerProps.isProposal);
  
  const {
    settings,
    visibility,
    displayTotalTime,
    displayTotalTimeMax,
    showMaxTotalTime,
  } = structure;
  const { useMinMax, isTotalTimeOverwritten, useWeekTime } = settings;
  const { hideTotalTime } = visibility;
  const className = useVisibilityModeCellClassName(hideTotalTime);
  
  const presetText = useWeekTime ? "pt-totalTimeWeeks" : "pt-totalTimeMonths";
  
  const unit = useMemo(() => (
    getFieldName(presetText)
  ), [useWeekTime, presetText])
  
  const handleTimeChange = (time, isMax) => {
    const key = isMax ? "max" : useMinMax ? "min" : "avg";
    settings.setCustomTotalTime({ [key]: time });
  };
  
  return (
    <TotalContainer
      commentPath="time"
      totalName="Time"
      showChatThread={isEditorOrClient && useClientActions}
      min={displayTotalTime}
      max={displayTotalTimeMax}
      showMaxValue={showMaxTotalTime}
      onValueChange={handleTimeChange}
      editable={allowEdition}
      showDecimals
      textSmaller
      allowEdition={allowEdition}
      className={className}
      visibilityHidden={hideTotalTime}
      setVisibility={visibility.setHideTotalTime}
      symbolEnd={ (
        <span>
         { unit }
        </span>
      ) }
      { ...containerProps }
    >
      {allowEdition && isTotalTimeOverwritten && (
        <ResetValueButton
          action={() => settings.resetTotalTimeValues()}
          className="text-4xl"
        >
          {t("common.restore_value")}
        </ResetValueButton>
      )}
    </TotalContainer>
  );
})

TotalTime.propTypes = {
  isProposal: bool,
  divider: bool,
  isEditorOrClient: bool,
  useClientActions: bool,
}