import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  BudgetTrackingStoreProvider, ProjectEditorStoreProviderV2, ProjectEditorStore,
} from "@stores";
import {
  checkProjectAccessQuery,
  fetchProjectStructureQuery,
  getCompanyLogoQuery,
  getCompanySettingsQuery,
  getProjectPresetQuery,
  getTeamMembersQuery,
  getWorkTypesQuery,
  getIssuesWorkType,
} from "@query";
import { useStores } from "@hooks";
import { home } from "@paths";
import { PageCircularProgress } from "@components";
import { ProjectLoadingAlert } from "@dialogs";
import { PROJECT_COMPANY_SETTINGS, USER_COMPANY_SETTINGS } from "@client";
import { BudgetTrackingView } from "./BudgetTrackingView";
import { PROJECT_TYPE } from "@utils";

export const BudgetTracking = () => {
  const { userStore } = useStores();
  const navigate = useNavigate();

  const { projectUuid } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const [store, setStore] = useState(null);
  const [hasError, setError] = useState(0);

  useEffect(() => {
    const abortController = new AbortController();
    onLoad();
    return () => abortController.abort();
  }, []);

  const onLoad = async () => {
    const hasAccess = await checkProjectAccessQuery(projectUuid);
    if (!hasAccess) {
      navigate(home, { replace: true });
      return;
    }

    try {
      const workTypes = await getWorkTypesQuery();
      const logo = await getCompanyLogoQuery();
      const blockedAverageAlert = await getCompanySettingsQuery(
        USER_COMPANY_SETTINGS.AVERAGE_ALERT(userStore.userUuid)
      );
      const blockedRiskAlert = await getCompanySettingsQuery(
        USER_COMPANY_SETTINGS.RISK_ALERT(userStore.userUuid)
      );
      const blockedValueAlert = await getCompanySettingsQuery(
        PROJECT_COMPANY_SETTINGS.VALUE_ALERT(projectUuid)
      );
      const hadDifferentValues = await getCompanySettingsQuery(
        PROJECT_COMPANY_SETTINGS.VALUE_PROJECT(projectUuid)
      );

      const teamMembers = await getTeamMembersQuery(userStore.workspaceUuid);

      const projectPreset = await getProjectPresetQuery(projectUuid);
      const projectData = await fetchProjectStructureQuery(
        userStore.userId,
        projectUuid,
        PROJECT_TYPE.STANDARD,
        Boolean(searchParams.get("new"))
      );

      const btIssues = await getIssuesWorkType(projectData.id);
      if (searchParams.get("new")) setSearchParams();

      setStore(new ProjectEditorStore({
        workTypes,
        logo,
        projectData,
        projectPreset,
        blockedAverageAlert: Boolean(blockedAverageAlert),
        blockedRiskAlert: Boolean(blockedRiskAlert),
        blockedValueAlert: Boolean(blockedValueAlert),
        hadDifferentValues: Boolean(hadDifferentValues),
        teamMembers,
        btIssues,
        projectType: PROJECT_TYPE.STANDARD,
      }));
    } catch (e) {
      setError(2);
    }
  };

  if (hasError) return <ProjectLoadingAlert structureError={hasError === 1} />;

  return !store ? (
    <PageCircularProgress />
  ) : (
    <BudgetTrackingStoreProvider>
      <ProjectEditorStoreProviderV2 value={store}>
        <BudgetTrackingView />
      </ProjectEditorStoreProviderV2>
    </BudgetTrackingStoreProvider>
  );
};
