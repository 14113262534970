import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore, useVisibilityModeCellClassName } from "@hooks";
import { ValueCell } from "@components";

export const HourCell = observer(({
  element,
  allowEdition,
  profitabilityMode
}) => {
  const structure = useStructureStore();
  const className = useVisibilityModeCellClassName(structure.visibility.hideSummaryHours);
  
  const { usesTwoValues, usesAnyValue } = structure;
  const {
    useMinMax,
    modifier,
    roundType,
    roundHours,
    usedRoundDecimals,
  } = structure.settings;
  const {
    displayHours,
    displayHoursMax,
    availableHours,
    isOnlyResource,
  } = element;
  
  const editable = allowEdition && !isOnlyResource && !profitabilityMode;
  
  const showDecimals = roundType !== 2 || !roundHours;
  
  const showMaxValue = usesTwoValues && (editable || displayHours !== displayHoursMax);
  
  const handleHoursChange = (hour, isMax) => {
    structure.historyManager.startGroup();
    const key = isMax ? "max" : useMinMax ? "min" : "avg";
    hour = parseFloat((hour * modifier).toPrecision(12));
    const diff = hour - element.cHours?.[key];
    element.parent.updateResourceHours(element.id, diff, key);
    element.setCustomHours({ [key]: hour });
    element.setHoursOverwrite(true, useMinMax ? "min" : "avg");
    element.parent.generateTimeline();
    structure.historyManager.stopGroup();
  };

  return (
    <ValueCell
      widest
      displayContent
      emptyInputs={!usesAnyValue}
      value={displayHours}
      valueMax={displayHoursMax}
      displayMax={showMaxValue}
      onValueChange={handleHoursChange}
      max={availableHours}
      editable={editable}
      showDecimals={showDecimals}
      useSeparator={profitabilityMode}
      decimals={usedRoundDecimals}
      className={className}
      textPresetClass="preset-summaryText"
      inputClassName="input-hours"
    />
  );
});

HourCell.propTypes = {
  element: object.isRequired,
  allowEdition: bool,
  profitabilityMode: bool,
};
