import { ACCESS_DOMAINS, APIMethod, fetchData } from "@client";

export async function getCurrencyExchangeRatesQuery(
  ...currencyCodes
) {
  try {
    const res = await fetchData("/api/rates/batch", APIMethod.GET, {
      noAuth: true,
      baseURL: ACCESS_DOMAINS.editor,
      mapRequestKeysToSnakeCase: false,
      params: {
        codes: currencyCodes.join(",")
      },
    });
    
    return res?.results || [{value:1},{value:1}];
  } catch(e) {
    return [{value:1},{value:1}];
  }
}
