import { useEffect, useMemo, useState } from "react";
import { number, string, object } from "prop-types";
import { editor, pdfEditor, projectEdit, budgetTracking } from "@paths";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  useActiveProjectStore,
  useCheckProjectAccess,
  useStores,
} from "@hooks";
import {
  flattenProjectUsers,
  isNonExpandActionClick,
  universalDateParser,
} from "@utils";
import { ProjectEditionDialog } from "@dialogs";
import {
  AvatarList,
  ClearButton,
  Deadline,
  ProjectActionMenu,
  TagList,
} from "@components";
import { Paper, Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import classnames from "classnames";
import useStyle from "./Card.style";
import { Jira } from "@assets";

export const Card = ({ projectUuid, projectData }) => {
  const { stateStore, userStore } = useStores();
  const classes = useStyle();
  const { t } = useTranslation();
  const activeProjects = useActiveProjectStore();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  
  const { isOwner } = useCheckProjectAccess(projectData.users);
  const { isBusinessUser} = userStore;

  const { id, dueOn, name, content, tags, users, status, type, btSetUp, btExported } = projectData;
  const deadline = dueOn ? universalDateParser(dueOn) : undefined;
  
  const useBT = useMemo(() => (
    localStorage.getItem("new_jira_export")
  ), [])
  
  useEffect(() => {
    if (stateStore.memorizedProjectId === projectUuid) {
      setOpenModal(true);
      stateStore.memorizedProjectId = null;
    }
  }, []);

  const handleOpenModal = (state) => {
    setOpenModal(state);
  };

  const openEditor = (e) => {
    e.stopPropagation();
    navigate(editor(projectUuid));
  };

  const openBudgetTracking = (e) => {
    e.stopPropagation();
    navigate(budgetTracking(projectUuid))
  };

  const openPdfEditor = (e) => {
    e.stopPropagation();
    navigate(pdfEditor(projectUuid));
  };

  const handleCardClick = (e) => {
    if (activeProjects.isDragging) return;
    e.stopPropagation();
    if (isNonExpandActionClick(e, true))
      return;
    activeProjects.setCurrentProject(projectUuid);
    isMobile ? navigate(projectEdit(projectUuid)) : setOpenModal(true);
  };

  return (
    <Paper
      elevation={0}
      data-id={projectUuid}
      aria-label="project-card"
      className={classnames(classes.root, !isOwner && "noDrag")}
    >
      <Grid
        item
        container
        direction="column"
        className="p-6"
        onClick={handleCardClick}
      >
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          className="relative"
        >
          <h3
            className="cursor-pointer"
            style={{
              maxWidth: "85%",
              lineBreak: name.match(/\S{26,}/) ? "anywhere" : "auto",
            }}
          >
            {name.replace(/<(.|\n)*?>/g, "")}
          </h3>
          <ProjectActionMenu
            uuid={projectUuid}
            name={name}
            description={content}
            isOwner={isOwner}
            isBusiness={isBusinessUser}
            isMini={type === "mini"}
            id={id}
            projectBtSetUp={btSetUp}
            projectBtExported={btExported}
          />
        </Grid>
        <Deadline
          status={status}
          date={deadline}
          className={classes.deadline}
        />
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="mb-4"
        >
          <TagList
            tags={tags}
            ellipsisOnOverflow
            useMaxWidth
          />
        </Grid>
        <Grid
          item
          container
          wrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item container alignItems="center">
            <AvatarList users={flattenProjectUsers(users)} hideExcess />
          </Grid>

          <Grid
            item
            container
            wrap="nowrap"
            justifyContent="center"
            className={classes.actionButtonContainer}
          >
            {
              projectData.btExported && useBT &&
              <Tooltip title={t("views.bt.open_title")}>
                <ClearButton
                  onClick={openBudgetTracking}
                  size="small"
                  className={`noDrag mr-2 ${classes.actionButton}`}
                >
                  <Jira />
                </ClearButton>
              </Tooltip>
            }
            <ClearButton
              onClick={type === "mini" ? openPdfEditor : openEditor}
              size="small"
              className={`noDrag ${classes.actionButton}`}
            >
              {t("common.open")}
            </ClearButton>
          </Grid>
        </Grid>
      </Grid>
      {openModal && (
        <ProjectEditionDialog
          isOpen={openModal}
          handleModalOpenState={handleOpenModal}
          projectUuid={projectUuid}
        />
      )}
    </Paper>
  );
};

Card.propTypes = {
  projectUuid: string.isRequired,
  index: number.isRequired,
  projectData: object,
};
