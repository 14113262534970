import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { MONTH_WEEKS } from "project-structure";
import {
  useStructureStore,
  useSummaryTimeInputTooltipAlert,
  useVisibilityModeCellClassName
} from "@hooks";
import { ValueCell, ResetValueButton } from "@components";

export const TimeCell = observer(({
  element,
  allowEdition,
  profitabilityMode
}) => {
  const { t } = useTranslation();
  const structure = useStructureStore();
  const tooltip = useSummaryTimeInputTooltipAlert();
  const className = useVisibilityModeCellClassName(structure.visibility.hideSummaryTime);
  
  const { usesTwoValues, usesAnyValue } = structure;
  const { useMinMax, isTimelineTableUsed, useWeekTime } = structure.settings;
  const { displayTime, displayTimeMax, isTimeOverwritten } = element;
  
  const editable = allowEdition && !profitabilityMode && !isTimelineTableUsed;
  const showMaxValue = usesTwoValues && (editable || displayTime !== displayTimeMax);
  
  const handleTimeChange = (time, isMax) => {
    const key = isMax ? "max" : useMinMax ? "min" : "avg";
    element.setCustomTime({ [key]: time * (useWeekTime ? 1 : MONTH_WEEKS) });
  };
  
  const handleReset = () => {
    element.resetTimeValues();
  };
  
  return (
    <ValueCell
      widest
      displayContent
      showDecimals
      emptyInputs={!usesAnyValue}
      value={displayTime}
      valueMax={displayTimeMax}
      displayMax={showMaxValue}
      onValueChange={handleTimeChange}
      warning={isTimeOverwritten}
      editable={editable}
      className={className}
      textPresetClass="preset-summaryText"
      inputClassName="input-time"
      { ...tooltip }
    >
      {allowEdition && isTimeOverwritten && (
        <ResetValueButton action={handleReset}>
          {t("views.editor.revert_value")}
        </ResetValueButton>
      )}
    </ValueCell>
  );
});

TimeCell.propTypes = {
  element: object.isRequired,
  allowEdition: bool,
  profitabilityMode: bool,
};
