import { useMemo } from "react";
import { arrayOf, bool, number, object, string } from "prop-types";
import { useStructureStore } from "@hooks";
import { observer } from "mobx-react";
import { CollapseValueCell } from "@components";
import { MonthValueCell } from "./components/MonthValueCell";
import { WeekValueCell } from "./components/WeekValueCell";
import { ChartCell } from "./components/ChartCell";
import { sampleColors } from "@utils";

export const TimelineValueCell = observer(({
  monthIndex,
  month=[],
  maxHours,
  timelineStarted,
  allowEdition,
  element,
  visible,
  displayContent = true,
  displayCellContent,
  color=sampleColors[1],
  timelineChartSegments,
}) => {
  const structure = useStructureStore();
  const { modifier, useTimelineChart, roundHours, roundType } = structure.settings;
  
  const hasMonthOverride = Boolean(month.find(w => w.override));
  
  const monthlyHours = (
    month.reduce((t, w) => t + (w.hours || 0), 0) / modifier
  );
  
  const handleValueChange = (hours) => {
    element?.setCustomTimelineMonthValue(parseFloat((hours * modifier).toPrecision(12)), monthIndex * 4, true);
  };
  const handleWeekValueChange = (week) => (hours) => {
    element?.setCustomTimelineMonthValue(parseFloat((hours * modifier).toPrecision(12)), monthIndex * 4 + week);
  };

  const handleReset = () => {
    element?.resetCustomTimelineWeekValue(monthIndex * 4, true);
  };
  const handleWeekReset = (week) => () => {
    element?.resetCustomTimelineWeekValue(monthIndex * 4 + week);
  };
  
  const forcedDisplayCellContent = useMemo(() => (
    typeof displayCellContent === "boolean" ? displayCellContent : (displayContent && (timelineStarted || hasMonthOverride))
  ), [displayCellContent, displayContent, timelineStarted, hasMonthOverride]);
  
  const isOpened = useMemo(() => (
    structure.settings.isTimelineMonthExpanded(monthIndex)
  ), [structure.settings.expandedTimelineMonths?.size]);
  
  const showDecimals = useMemo(() => (
    roundType !== 2 || !roundHours
  ), [roundType, roundHours]);
  
  const cellProps = {
    allowEdition,
    displayCellContent: forcedDisplayCellContent,
    displayContent,
    maxHours,
    showDecimals,
  }
  
  return (
    <CollapseValueCell
      isOpened={isOpened}
      narrowChildren
      useBorder
      visible={visible}
      isFirst={monthIndex === 0}
      innerClassName="opaque-tContent"
      cell={(
        useTimelineChart
          ? forcedDisplayCellContent && (
            <ChartCell
              isMonth
              index={monthIndex*4}
              color={color}
              value={monthlyHours}
              showDecimals={showDecimals}
              displayCellContent={forcedDisplayCellContent}
              timelineChartSegments={timelineChartSegments}
            />
          )
          : <MonthValueCell
            month={monthIndex}
            value={monthlyHours}
            hasOverride={hasMonthOverride}
            handleReset={handleReset}
            handleValueChange={handleValueChange}
            isOpened={isOpened}
            {...cellProps}
          />
      )}
    >
      {
        [...new Array(4).keys()]
          .map((index) => {
            const exists = Boolean(month[index]);
            if(displayContent && useTimelineChart) {
              return <ChartCell
                key={`week${index}`}
                color={color}
                index={monthIndex*4 + index}
                timelineChartSegments={timelineChartSegments}
                displayCellContent={forcedDisplayCellContent}
                value={ month[ index ]?.hours }
                showDecimals={showDecimals}
              />
            }
            
            return <WeekValueCell
              key={`week${index}`}
              week={index}
              value={ month[ index ]?.hours }
              hasOverride={ month[ index ]?.override }
              handleReset={ handleWeekReset(index) }
              handleValueChange={ handleWeekValueChange(index) }
              { ...cellProps }
              allowEdition={allowEdition && exists}
              displayCellContent={forcedDisplayCellContent && exists}
            />
          })
      }
    </CollapseValueCell>
  );
});

TimelineValueCell.propTypes = {
  element: object,
  monthIndex: number.isRequired,
  month: arrayOf(object),
  maxHours: number,
  allowEdition: bool,
  displayContent: bool,
  color: string,
};